import { FC } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import { MentorShort } from '@/service/api/models/homepage';
import { WithHomepagePreviewData } from '@/service/serverSideProps/getFeaturedMentors';

import MentorSliderItem from './components/item';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
  mentors: WithHomepagePreviewData<MentorShort>[];
}

const MentorsSlider: FC<Props> = ({
  className,
  mentors,
}) => (
  <div {...{
    className: cx(styles.container, className),
  }}>
    {
      mentors.map((mentor) => (
        <MentorSliderItem {...{
          key: mentor.uuid,
          avatar: mentor.avatar,
          uuid: mentor.uuid,
          name: mentor.name,
          description: mentor.homepagePreviewData.shortBio,
          tags: mentor.homepagePreviewData.tagList,
        }} />
      ))
    }
  </div>
);

export default MentorsSlider;

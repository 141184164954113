import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cx, LinariaClassName } from '@linaria/core';

import Picture from '@/components/picture';

import { creatHrefWithUTM } from '@/helpers/withUTM';

import { Image } from '@/service/api/models/homepage';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
  avatar: Image;
  uuid: string;
  name: string;
  description: string;
  tags: string[];
}

const MentorSliderItem: FC<Props> = ({
  className,
  uuid,
  name,
  description,
  tags,
  avatar,
}) => {
  const router = useRouter();
  const mentorHref = creatHrefWithUTM(router, `/mentors/${uuid}`);

  return (
    <div>
      <div {...{
        className: cx(styles.container, className),
      }}>
        <div {...{
          className: styles.heading,
        }}>
          <Link {...{
            href: mentorHref,
          }}>
            <a {...{
              href: mentorHref,
            }}>
              <Picture {...{
                width: 64,
                height: 64,
                image: avatar,
                title: name,
                className: styles.avatar,
              }} />
            </a>
          </Link>

          <div {...{
            className: styles.about,
          }}>
            <Link {...{
              href: mentorHref,
            }}>
              <a {...{
                href: mentorHref,
                className: styles.nameLink,
              }}>
                <p {...{
                  className: styles.name,
                }}>
                  { name }
                </p>
              </a>
            </Link>

            <p {...{
              className: styles.description,
            }}>
              { description }
            </p>
          </div>
        </div>

        <div {...{
          className: styles.tagList,
        }}>
          { tags.map((tag, index) => (
            <p {...{
              key: `${tag}-${index}`,
              className: styles.tag,
            }}>
              { tag }
            </p>
          )) }
        </div>

        <Link {...{
          href: mentorHref,
        }}>
          <a {...{
            className: styles.button,
            component: 'a',
          }}>
            View profile
          </a>
        </Link>
      </div>
    </div>
  );
};

export default MentorSliderItem;

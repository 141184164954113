import { FC } from 'react';
import { LinariaClassName } from '@linaria/core';

interface Props {
  className?: LinariaClassName;
}

/* eslint-disable max-len */
const AppleStoreLogo: FC<Props> = ({
  className,
}) => (
  <svg width="104" height="24" viewBox="0 0 104 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M18.9702 12.2103C18.9435 9.3096 21.4013 7.8984 21.5137 7.8327C20.1217 5.85 17.9643 5.5791 17.2061 5.5575C15.3941 5.3712 13.6364 6.6168 12.7133 6.6168C11.7719 6.6168 10.3504 5.5755 8.81846 5.6061C6.84707 5.6358 5.0028 6.7509 3.99131 8.4825C1.90385 12.0132 3.46069 17.2017 5.46065 20.0556C6.46108 21.4533 7.6301 23.0139 9.16023 22.959C10.6572 22.8987 11.2164 22.0266 13.0229 22.0266C14.8128 22.0266 15.3379 22.959 16.8984 22.9239C18.505 22.8987 19.5165 21.5199 20.4819 20.1096C21.6381 18.5076 22.1023 16.9299 22.1208 16.8489C22.083 16.8363 19.0006 15.687 18.9702 12.2103Z" fill="black"/>
    <path d="M16.0223 3.6801C16.8275 2.6964 17.3784 1.3581 17.2254 0C16.0601 0.0504 14.6028 0.7875 13.7635 1.7496C13.021 2.5974 12.3578 3.987 12.5291 5.2938C13.8381 5.3892 15.1822 4.6485 16.0223 3.6801Z" fill="black"/>
    <path d="M40.3498 17.8169H38.2577L37.1118 14.2988H33.1284L32.0368 17.8169H30L33.9465 5.83969H36.384L40.3498 17.8169ZM36.7663 12.8228L35.7299 9.69529C35.6203 9.37579 35.4149 8.62339 35.1118 7.43899H35.075C34.9543 7.94839 34.7599 8.70079 34.4927 9.69529L33.4748 12.8228H36.7663Z" fill="black"/>
    <path d="M50.4995 13.3926C50.4995 14.8614 50.0932 16.0224 49.2807 16.8747C48.553 17.6334 47.6493 18.0123 46.5705 18.0123C45.4061 18.0123 44.5696 17.6037 44.0602 16.7865H44.0234V21.336H42.0593V12.0237C42.0593 11.1003 42.0345 10.1526 41.9866 9.18057H43.7138L43.8235 10.5495H43.8603C44.5153 9.51807 45.5093 9.00327 46.8432 9.00327C47.886 9.00327 48.7566 9.40556 49.453 10.2111C50.1513 11.0175 50.4995 12.0777 50.4995 13.3926ZM48.4986 13.4628C48.4986 12.6222 48.3052 11.9292 47.9164 11.3838C47.4917 10.815 46.9215 10.5306 46.2066 10.5306C45.7221 10.5306 45.2817 10.689 44.8884 11.0013C44.4941 11.3163 44.2362 11.7276 44.1155 12.237C44.0547 12.4746 44.0243 12.669 44.0243 12.822V14.262C44.0243 14.8902 44.2214 15.4203 44.6157 15.8532C45.01 16.2861 45.5222 16.5021 46.1523 16.5021C46.892 16.5021 47.4678 16.2231 47.8796 15.6669C48.2923 15.1098 48.4986 14.3754 48.4986 13.4628Z" fill="black"/>
    <path d="M60.6668 13.3926C60.6668 14.8614 60.2605 16.0224 59.4471 16.8747C58.7203 17.6334 57.8166 18.0123 56.7378 18.0123C55.5734 18.0123 54.7369 17.6037 54.2284 16.7865H54.1916V21.336H52.2276V12.0237C52.2276 11.1003 52.2027 10.1526 52.1548 9.18057H53.8821L53.9917 10.5495H54.0285C54.6826 9.51807 55.6766 9.00327 57.0114 9.00327C58.0533 9.00327 58.9239 9.40556 59.6221 10.2111C60.3177 11.0175 60.6668 12.0777 60.6668 13.3926ZM58.6659 13.4628C58.6659 12.6222 58.4715 11.9292 58.0828 11.3838C57.6581 10.815 57.0897 10.5306 56.3739 10.5306C55.8885 10.5306 55.449 10.689 55.0548 11.0013C54.6605 11.3163 54.4035 11.7276 54.2828 12.237C54.2229 12.4746 54.1916 12.669 54.1916 12.822V14.262C54.1916 14.8902 54.3887 15.4203 54.7812 15.8532C55.1754 16.2852 55.6876 16.5021 56.3196 16.5021C57.0593 16.5021 57.6351 16.2231 58.0469 15.6669C58.4596 15.1098 58.6659 14.3754 58.6659 13.4628Z" fill="black"/>
    <path d="M72.0347 14.4581C72.0347 15.4769 71.6727 16.3058 70.9459 16.9457C70.1472 17.645 69.0353 17.9942 67.6065 17.9942C66.2873 17.9942 65.2297 17.7458 64.4292 17.2481L64.8843 15.6488C65.7465 16.1582 66.6926 16.4138 67.7235 16.4138C68.4632 16.4138 69.0389 16.25 69.4526 15.9242C69.8643 15.5984 70.0698 15.161 70.0698 14.6156C70.0698 14.1296 69.9003 13.7201 69.5603 13.388C69.2223 13.0559 68.6576 12.7472 67.869 12.4619C65.7226 11.6798 64.6503 10.5341 64.6503 9.02746C64.6503 8.04286 65.0261 7.23555 65.7788 6.60735C66.5286 5.97825 67.5291 5.66415 68.7801 5.66415C69.8957 5.66415 70.8224 5.85405 71.5621 6.23295L71.0711 7.79716C70.3802 7.42996 69.599 7.24636 68.7248 7.24636C68.0339 7.24636 67.4941 7.41286 67.1072 7.74406C66.7801 8.04015 66.6162 8.40106 66.6162 8.82856C66.6162 9.30196 66.8032 9.69345 67.179 10.0013C67.506 10.2857 68.1002 10.5935 68.9625 10.9256C70.0173 11.3405 70.792 11.8256 71.2904 12.3818C71.7869 12.9362 72.0347 13.6301 72.0347 14.4581Z" fill="black"/>
    <path d="M78.5283 10.6203H76.3635V14.8134C76.3635 15.8799 76.7449 16.4127 77.5095 16.4127C77.8605 16.4127 78.1516 16.383 78.3819 16.3236L78.4362 17.7807C78.0493 17.922 77.5399 17.9931 76.9088 17.9931C76.1332 17.9931 75.527 17.7618 75.0895 17.3001C74.6537 16.8375 74.4345 16.0617 74.4345 14.9718V10.6185H73.1448V9.17851H74.4345V7.59721L76.3635 7.02841V9.17851H78.5283V10.6203Z" fill="black"/>
    <path d="M88.2959 13.4275C88.2959 14.755 87.9072 15.8449 87.1315 16.6972C86.3181 17.5747 85.2384 18.0121 83.8925 18.0121C82.5955 18.0121 81.5628 17.5918 80.7926 16.7512C80.0225 15.9106 79.6375 14.8495 79.6375 13.5706C79.6375 12.2323 80.0336 11.1361 80.8286 10.2838C81.6217 9.4306 82.6922 9.004 84.0381 9.004C85.3351 9.004 86.3789 9.4243 87.1665 10.2658C87.9201 11.0821 88.2959 12.136 88.2959 13.4275ZM86.2582 13.4896C86.2582 12.6931 86.0841 12.01 85.7313 11.4403C85.3195 10.7509 84.7308 10.4071 83.9681 10.4071C83.1786 10.4071 82.5789 10.7518 82.1671 11.4403C81.8143 12.0109 81.6402 12.7048 81.6402 13.5256C81.6402 14.3221 81.8143 15.0052 82.1671 15.574C82.5918 16.2634 83.185 16.6072 83.9506 16.6072C84.7004 16.6072 85.2891 16.2562 85.7138 15.556C86.0758 14.9755 86.2582 14.2852 86.2582 13.4896Z" fill="black"/>
    <path d="M94.6805 10.8681C94.4861 10.833 94.2788 10.815 94.0614 10.815C93.3705 10.815 92.8362 11.0697 92.4603 11.58C92.1333 12.03 91.9693 12.5988 91.9693 13.2855V17.817H90.0062L90.0246 11.9004C90.0246 10.905 89.9998 9.99867 89.9509 9.18147H91.6616L91.7335 10.8339H91.7878C91.9951 10.266 92.3221 9.80877 92.7698 9.46587C93.2074 9.15717 93.68 9.00327 94.1894 9.00327C94.3709 9.00327 94.5349 9.01586 94.6805 9.03836V10.8681Z" fill="black"/>
    <path d="M103.462 13.09C103.462 13.4338 103.439 13.7236 103.39 13.9603H97.4984C97.5214 14.8135 97.8061 15.466 98.3533 15.916C98.8498 16.3183 99.4919 16.5199 100.28 16.5199C101.153 16.5199 101.949 16.384 102.665 16.1113L102.972 17.4433C102.136 17.7997 101.148 17.977 100.009 17.977C98.6379 17.977 97.5619 17.5828 96.7789 16.7953C95.9977 16.0078 95.6062 14.9503 95.6062 13.6237C95.6062 12.3214 95.9701 11.2369 96.6988 10.372C97.4615 9.44861 98.4924 8.98691 99.7894 8.98691C101.063 8.98691 102.028 9.44861 102.683 10.372C103.202 11.1055 103.462 12.0127 103.462 13.09ZM101.589 12.5923C101.602 12.0235 101.474 11.5321 101.208 11.1172C100.868 10.5835 100.346 10.3171 99.6429 10.3171C99.0009 10.3171 98.4785 10.5772 98.0797 11.0992C97.7526 11.5141 97.5582 12.0118 97.4984 12.5914H101.589V12.5923Z" fill="black"/>
  </svg>
);

export default AppleStoreLogo;

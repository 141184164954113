import { MutableRefObject, useEffect, useState } from 'react';

export const useIsInViewport = (ref: MutableRefObject<any>, initialState = false) => {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(initialState);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => (
      setIsIntersecting(entry.isIntersecting)
    ));

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};
